import { API_ROUTE } from "../conexion"

async function addLog({ tipo = null, usuario = null, validUser = false, transferStatus = 0, importe = null }) {

  console.log('log test', usuario)
  console.log('log test', importe)

  let msg;
  let log = {}
  log.tipo = tipo
  log.usuario = usuario
  log.importe = importe
  log.quality = Math.floor(Math.random() * (91 - 80) + 80)

  if (transferStatus === 1) {
    log.similarity = Math.floor(Math.random() * (100 - 90) + 90)
  } else if (transferStatus === 2) {
    log.similarity = Math.floor(Math.random() * (90 - 70) + 70)
  } else if (transferStatus === 3) {
    log.similarity = Math.floor(Math.random() * 70)
  } else {
    validUser ?
      log.similarity = Math.floor(Math.random() * (96 - 90) + 90) :
      log.similarity = Math.floor(Math.random() * (56 - 5) + 5)
  }

  if (transferStatus === 1) {
    log.riesgo_total = Math.floor(Math.random() * 551)
  } else if (transferStatus === 2) {
    log.riesgo_total = Math.floor(Math.random() * (801 - 551) + 551)
  } else if (transferStatus === 3) {
    log.riesgo_total = Math.floor(Math.random() * (1001 - 801) + 801)
  } else {
    validUser ?
      log.riesgo_total = Math.floor(Math.random() * 201) :
      log.riesgo_total = Math.floor(Math.random() * (1001 - 801) + 801)
  }

  // Browser
  let sBrowser, sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Chrome";
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Safari";
  } else if (sUsrAg.indexOf("Opera") > -1) {
    sBrowser = "Opera";
  } else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Firefox";
  } else if (sUsrAg.indexOf("MSIE") > -1) {
    sBrowser = "Internet Explorer";
  }
  log.browser = sBrowser

  // API - IP | Country | City
  let apiResponse = await fetch("https://ipinfo.io/?token=b4fb2e77f4d765")
  let apiJson = await apiResponse.json()
  log.ip = apiJson.ip
  log.country = apiJson.country
  log.city = apiJson.city
  log.isp = apiJson.org

  // PHP - Operative System
  let phpResponse = await fetch(API_ROUTE + "get_os.php")
  let phpJson = await phpResponse.json()
  log.os = phpJson.os

  // Agrego los datos al login
  await fetch(API_ROUTE + "add_log.php", {
    method: 'POST',
    body: JSON.stringify(log),
  }).then(res => res.json())
    .then(response => {
      if (response.success) {
        msg = response.msg
      }
    })

  return msg
}

export default addLog