import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FooterMini from '../components/shared/FooterMini';
import Message from '../components/shared/Message';

import { API_ROUTE } from "../conexion";
import addLog from "../functions/addLog";
import { withNamespaces, Trans } from 'react-i18next';
import i18next from 'i18next';

function Login(props) {
  const { registerStatus, validUser = true } = props.location;
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  let [dbError, setdbError] = useState(null);

  const handleUserChange = (e) => setUser(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)

  // Your code here
  // if (typeof window.pressedKey() === 'function') {
  //   window.pressedKey();
  // }

  // async function setDevicePrintAndSendForm(e) {
  //    e.preventDefault(); 
  //   var form = document.forma;
  //   form.deviceprint.value = await getDevicePrini18next.t();
  //   encodeDevicePrini18next.t(form); 
  //   if (await formValidation(e)) {
  //     document.forma.submii18next.t();
  //   }
  // }

  useEffect(() => {
    // your post layout code (or 'effect') here.
    if (typeof window.pressedKey === 'function') {
      window.pressedKey();
    }
  },
    // array of variables that can trigger an update if they change. Pass an
    // an empty array if you just want to run it once after component mounted. 
    [])



  const onSubmit = async (e) => {
    e.preventDefault();


    // Validar campos
    let errors = {}
    if (!user) errors.usuario = i18next.t('required_user');
    if (!password) errors.password = i18next.t('password_empty');
    setErrors(errors)

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    // checkeo contra base de datos, usuario y contraseña correctos
    let data = {}
    data.user = user
    data.password = password
    // obtener bandera de usuario logueado
    // data.userLogin = user === localStorage.userLogin;
    data.userLogin = localStorage.userLogin === 'true' && !validUser ? true : false;

    // valido que funcion del cdn exista y obtengo cadena codificada

    if (typeof window.encode_deviceprint === 'function') {
      try {
        data.devicePrint = await window.encode_deviceprint();
      } catch (e) {
        console.log('Used failback');
        data.devicePrint = "";
      }

    }

    // Realizo la consulta a la base de datos
    fetch(API_ROUTE + "login.php", {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {

        if (!response){
          setdbError("Ocurrió un problema al realizar la transacción, inténtelo de nuevo más tarde");
          return;
        } 

        if (response.success) {
          // Cargo los datos en el log
          addLog({
            tipo: "ingreso",
            usuario: user,
            validUser: validUser
          })
          // if (validUser) {
          localStorage.setItem('userLogin', null);
          // }
          // Verifico el estado de la variable validUser
          console.log("🚀 ~ file: Login.js ~ line 80 ~ onSubmit ~ response.data ", response.data);
          validUser ?
            props.history.push({ pathname: '/dashboard', data: response.data }) :
            props.history.push('/login-fail');

        } else {
          setdbError(response.msg)
          props.history.push('/login-fail');
        }
      })
  }

  return (
    <div id="login-layout">
      {registerStatus && <Message />}
      <section className="login">
        <div className="background-grey"></div>
        <div className="container">

          <div className="row">
            <div className="col col-header">
              <Link className="back-link" to={{ pathname: "/", validUser: false }}>{i18next.t('return')}</Link>
              <Link to={{ pathname: "/", validUser: true }}>
                <img className="logo" src="img/logo.png" alt="" />
              </Link>
              <div className="spacer"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-center col-lg-6">
              <h2>
                <Trans i18nKey="Hello_Online_Banking">
                  <strong>¡Hola!</strong> Bienvenido a <span>Online Banking</span>
                </Trans>
              </h2>
              <div className="card">
                <div className="text-center">
                  <img className="icon" src="img/password.svg" alt="" />
                  <span className="form-title">{i18next.t('Click_operate')}</span>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="usuario">{i18next.t('User')}</label>
                    <input
                      type="text"
                      className={`form-control ${errors.usuario && "is-invalid"}`}
                      id="usuario"
                      name="usuario"
                      placeholder={i18next.t('Login_user')}
                      onChange={handleUserChange}
                      value={user || ''} />
                    {errors.usuario && (
                      <div className="invalid-feedback">{errors.usuario}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">{i18next.t('Password')}</label>
                    <input
                      type="password"
                      className={`form-control ${errors.password && "is-invalid"}`}
                      id="password"
                      name="password"
                      placeholder={i18next.t('Enter_password')}
                      onChange={handlePasswordChange}
                      value={password || ''} />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  {dbError && <div className="text-danger text-center">{dbError}</div>}
                  <button className="btn btn-success" type="submit">{i18next.t('Enter')}</button>
                </form>
                <span className="text-center">
                  <Trans i18nKey="an_account">
                    ¿No Tienes Cuenta?
                    <Link className="go-registro" to="/registro">Activarla</Link>
                  </Trans>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterMini />
    </div>
  );
}

export default withNamespaces()(Login);
