import React, { useState } from "react";
import addLog from "../../functions/addLog";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import sendTransaction from "../../functions/transaction";
import getDevicePrint from "../../functions/getDevicePrint";

function NuevoDestinatario({ monto, changeStep, updateImporte, user }) {
  const [importe, setImporte] = useState(null);
  const [cuenta, setCuenta] = useState(null);
  const [errors, setErrors] = useState({});

  // Actualizo el valor del importe
  const onChangeImporte = (e) => setImporte(e.target.value);

  // Actualizo el valor de la cuenta
  const onChangeCuenta = (e) => setCuenta(e.target.value);

  const transferir = async (e) => {
    e.preventDefault();

    let data = {
      user,
      amount: importe * 100,
      account: cuenta,
      sameBank: false,
      meToMe: false,
      haciaOtros: true,
      ctaInscrita: false,
      devicePrint: "",
    };
    data.devicePrint = await getDevicePrint();

    // Validar campos
    let errors = {};
    if (!importe) errors.importe = i18next.t("empty_amount");
    else if (isNaN(importe)) errors.importe = i18next.t("number_amount");
    else if (monto < importe) {
      errors.importe = i18next.t("no_balance");
      console.log("no_balance ...");
      await sendTransaction(data);
    }

    if (!cuenta) errors.cuenta = i18next.t("empty_account");
    else if (isNaN(cuenta)) errors.cuenta = i18next.t("numeric_account");

    setErrors(errors);

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    let res = await sendTransaction(data);

    if (res && res.status === 0) {
      //declino
      // Cargo los datos en el log
      addLog({
        tipo: "transferencia",
        usuario: user,
        transferStatus: 3,
        importe: importe,
      });
      // Voy a Transferencia fallida
      changeStep(6);
      return;
    }
    
    updateImporte(importe);

    if (res && res.status === 1) {
      //Verifar cuenta
      changeStep(4);
      return;
    }

    // transferencia exitosa
    changeStep(5);



    //validar comportamiento funcionalmente
    /*
    if (importe <= 500) {
      updateImporte(importe);
      // Voy a Verificar cuenta
      changeStep(4)
    } else {
      // Cargo los datos en el log
      addLog({
        tipo: "transferencia",
        usuario: user,
        transferStatus: 3,
        importe: importe
      })
      // Voy a Transferencia fallida
      changeStep(6)
    }
    */
  };

  return (
    <React.Fragment>
      <div id="monto-transferir" className="drawer-content">
        <h2 className="drawer-title">{i18next.t("new_recipient")}</h2>

        <form onSubmit={transferir}>
          <div className="form-group form-group-custom">
            <label htmlFor="cuenta">{i18next.t("account_to_transfer")}</label>
            <input
              type="text"
              className={`form-control form-control-custom ${
                errors.cuenta && "is-invalid"
              }`}
              id="cuenta"
              name="cuenta"
              value={cuenta || ""}
              placeholder="Ingrese cuenta"
              onChange={onChangeCuenta}
            />
            <div className="invalid-feedback">{errors.cuenta}</div>
          </div>
          <div className="form-group form-group-custom">
            <label htmlFor="cuenta">{i18next.t("amount")}</label>
            <input
              type="text"
              className={`form-control form-control-custom form-control-importe ${
                errors.importe && "is-invalid"
              }`}
              id="importe"
              name="importe"
              value={importe || ""}
              placeholder="$0.00"
              onChange={onChangeImporte}
            />
            <div className="invalid-feedback">{errors.importe}</div>
          </div>
          <input type="submit" className="sr-only" />
        </form>
        <button className="btn btn-success" onClick={transferir}>
          {i18next.t("transfer")}
        </button>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(NuevoDestinatario);
