import React, { useState } from 'react';

import { API_ROUTE } from "../conexion";
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

function BacklogLogin(props) {
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  let [dbError, setdbError] = useState(null);

  const handleUserChange = (e) => setUser(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)

  const onSubmit = (e) => {
    e.preventDefault();

    // Validar campos
    let errors = {}
    if (!user) errors.usuario = i18next.t('required_user');
    if (!password) errors.password = i18next.t('password_empty');
    setErrors(errors)

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    // checkeo contra base de datos, usuario y contraseña correctos
    let data = {}
    data.user = user
    data.password = password

    // Realizo la consulta a la base de datos
    fetch(API_ROUTE + "login.php", {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        if (response.success) {
          localStorage.setItem('kyma-user', data.user);
          props.history.push('/backend/ingresos')
        } else {
          setdbError(response.msg)
        }
      })
  }

  return (
    <div id="backlog-layout">
      <section className="login">
        <div className="container">

          <div className="row">
            <div className="col col-header">
              <img className="logo" src="img/logo-white.png" alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col-center col-lg-6">

              <h2>{i18next.t('welcome_backend')}</h2>

              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="usuario">{i18next.t('User')}</label>
                  <input
                    type="text"
                    className={`form-control ${errors.password && "is-invalid"}`}
                    id="usuario"
                    name="usuario"
                    placeholder={i18next.t('Login_user')}
                    onChange={handleUserChange}
                    value={user || ''} />
                  {errors.usuario && (
                    <div className="invalid-feedback">{errors.usuario}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">{i18next.t('Password')}</label>
                  <input
                    type="password"
                    className={`form-control ${errors.password && "is-invalid"}`}
                    id="password"
                    name="password"
                    placeholder={i18next.t('Enter_password')}
                    onChange={handlePasswordChange}
                    value={password || ''} />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                {dbError && <div className="text-danger text-center">{dbError}</div>}
                <button className="btn btn-success" type="submit">{i18next.t('Enter')}</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withNamespaces()(BacklogLogin);