import React from 'react';
import { withNamespaces } from 'react-i18next';
function Slider({ t }) {
  return (
    <section id="slider">
      <div className="container">
        <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={t('banner_a')} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={t('banner_b')} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={t('banner_c')} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withNamespaces()(Slider);

