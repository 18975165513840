import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
function Footer({ t }) {
  return (
    <footer>
      <div className="footer-top d-none d-lg-block">
        <div className="container">
          <img src="img/logo-footer.png" alt="logo" />
          <div className="row">
            <div className="col">
              <ul>
                <li className="list-title">{t('PERSONS')}</li>
                <li>{t('Benefits')}</li>
                <li>{t('Cards')}</li>
                <li>{t('Accounts')}</li>
                <li>{t('Credits')}</li>
                <li>{t('Investments')}</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li className="list-title">{t('PREMIUM')}</li>
                <li>{t('Products')}</li>
                <li>{t('Benefits')}</li>
                <li>{t('Travels')}</li>
                <li>{t('Investments')}</li>
                <li>{t('Credits')}</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li className="list-title">{t('Companies').toUpperCase()}</li>
                <li>{t('Investments')}</li>
                <li>{t('Accounts')}</li>
                <li>{t('Financing')}</li>
                <li>{t('Payments')}</li>
                <li>{t('Collections')}</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li className="list-title">{t('About_us').toUpperCase()}</li>
                <li>{t('who')}</li>
                <li>{t('Work_with_us')}</li>
                <li>{t('Comunication')}</li>
                <li>{t('Community')}</li>
                <li>{t('Actions')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span>
          <Trans i18nKey="RIGHTS_RESERVED"
            defaults=" Kyma Bank {{ date }}. TODOS LOS DERECHOS RESERVADOS."
            values={{ date: new Date().getFullYear() }}>
          </Trans>
        </span>
      </div>
    </footer>
  );
}

export default withNamespaces()(Footer);