import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import i18next from 'i18next';
function TransferenciaFallida(props) {
  return (
    <React.Fragment>
      <div id="transferencia-fallida" className="drawer-content">

        <div>
          <h2 className="drawer-title text-danger">{i18next.t('Latest_news')}</h2>
          <p className="drawer-legend">{i18next.t('For_security')}</p>
          <div className="alert alert-primary mb-0" role="alert">
            <span>
              <Trans i18nKey="Call_for_more">Llame al <b>0800 555 5555</b> para más información</Trans>
            </span>
          </div>
        </div>

        <img className="img-fluid" src="img/no-stopping.svg" alt="" />

        <button className="btn btn-outline-dark" onClick={() => props.hideDrawer()}>{i18next.t('To_close')}</button>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(TransferenciaFallida);