import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

function DashboardProductos({ monto }) {
  return (
    <section id="dashboard-productos">
      <div className="container">
        <h2 className="section-title">{i18next.t('Your_products')}</h2>
        <div className="row">
          <div className="col-card 
          col-12 col-lg-4">
            <div className="card">
              <div className="card-top">
                <span className="card-title">{i18next.t('Accounts')}</span>
                <span>{i18next.t('Total_balances')}</span>
              </div>
              <div className="card-amount">${new Intl.NumberFormat().format(monto)}</div>
              <div className="card-link">{i18next.t('Accounts')} →</div>
            </div>
          </div>
          <div className="col-card 
          col-12 col-lg-4">
            <div className="card">
              <div className="card-top">
                <span className="card-title">Visa 0965</span>
                <span>{i18next.t('Latest_Consumption')}</span>
              </div>
              <div className="card-amount">${new Intl.NumberFormat().format(931.23)}</div>
              <div className="card-link">{i18next.t('Cards')} →</div>
            </div>
          </div>
          <div className="col-card 
          col-12 col-lg-4">
            <div className="card">
              <div className="card-top">
                <span className="card-title">Kyma Club</span>
              </div>
              <div className="card-amount">190.000 pts</div>
              <div className="card-link">{i18next.t('Redeem')} →</div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default withNamespaces()(DashboardProductos);