import React, { useState, useEffect } from 'react';
import BacklogHeader from '../components/backlog/BacklogHeader';
import FooterMini from '../components/shared/FooterMini';
import BacklogCards from '../components/backlog/BacklogCards';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
import { API_ROUTE } from '../conexion';

function BacklogTransferencias(props) {

  const [transferencias, setTransferencias] = useState([])

  let user = {};
  user.usuario = localStorage.getItem('kyma-user')

  useEffect(() => {
    fetch(API_ROUTE + 'get_transferencias.php', {
      method: 'POST',
      body: JSON.stringify(user),
    }).then(function (response) {
      return response.json();
    })
      .then(function (data) {
        setTransferencias(data);
      });
  }, [])

  const logOut = () => {
    localStorage.setItem("kyma-user", "")
    props.history.push("/backend")
  }

  return (
    <div>
      <BacklogHeader logOut={logOut} />

      <div className="container container-backlog">
        <div className="row">
          <div className="col">
            <div className="table-card table-card-transferencias">
              <div className="table-header">
                <span className="table-fecha">{i18next.t('date')}</span>
                <span className="table-usuario">{i18next.t('User')}</span>
                <span className="table-importe">{i18next.t('amount')}</span>
                {/* <span className="table-quality">Profile Quality</span> */}
                {/* <span className="table-similarity">Similarity</span> */}
                <span className="table-riesgo">{i18next.t('total_risk')}</span>
              </div>
              <BacklogCards data={transferencias} />
            </div>
          </div>
        </div>
      </div>

      <FooterMini />
    </div >
  );
}

export default withNamespaces()(BacklogTransferencias);