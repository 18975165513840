import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

function TransferenciaExitosa(props) {
  return (
    <React.Fragment>
      <div id="transferencia-exitosa" className="drawer-content">

        <div>
          <h2 className="drawer-title">{i18next.t('Successful_transfer')}</h2>
          <p className="drawer-legend mb-0">{i18next.t('Was_successful')}</p>
        </div>

        <img className="img-fluid" src="img/transferencia-exitosas.png" alt="" />

        <button className="btn btn-outline-dark" onClick={() => props.hideDrawer()}>{i18next.t('To_close')}</button>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(TransferenciaExitosa);