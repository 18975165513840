import React, { useState } from 'react';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import DashboardSubHeader from '../components/dashboard/DashboardSubHeader';
import DashboardAccesos from '../components/dashboard/DashboardAccesos';
import DashboardProductos from '../components/dashboard/DashboardProductos';
import DatosUtiles from '../components/shared/DatosUtiles';
import Footer from '../components/shared/Footer';
import DrawerLayout from '../components/drawers/DrawerLayout';

function Dashboard(props) {
  const [drawer, setDrawer] = useState(false);
  const [monto, setMonto] = useState(10000);

  const showDrawer = () => {
    setDrawer(true);
    // prevent scrolling on desktop
    document.body.style.overflow = 'hidden';
  }

  const hideDrawer = () => {
    setDrawer(false);
    // prevent scrolling on desktop
    document.body.style.overflow = 'auto';
  }

  const updateMonto = (monto) => {
    setMonto(monto)
  }

  return (
    <div id="dashboard" >
      <DashboardHeader user={props.location.data} />
      <DashboardSubHeader />
      <DashboardAccesos showDrawer={showDrawer} />
      <DashboardProductos monto={monto} />
      <DatosUtiles />
      <Footer />
      <DrawerLayout
        user={props.location.data.user}
        drawerIsShow={drawer}
        hideDrawer={hideDrawer}
        monto={monto}
        updateMonto={updateMonto}
      />
    </div>
  );
}

export default Dashboard;
