import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

function SelectDestinatario(props) {
  const nextStep = (step, id) => {
    props.selectDestinatario(id);
    props.changeStep(step);
  }

  return (
    <React.Fragment>
      <div id="select-destinatario" className="drawer-content">
        <h2 className="drawer-title">{i18next.t('who_transfer')}</h2>
        <p className="drawer-legend">{i18next.t('contact_transfer')}</p>
        <h3 className="section-title">{i18next.t('recipients')}</h3>
        {
          props.destinatarios.map(destinatario => (
            <div key={destinatario.id} className="card" onClick={() => nextStep(2, destinatario.id)}>
              <span className="destinatario-name">{destinatario.name}</span>
              <span className="destinatario-cbu">{i18next.t('account')} {destinatario.CBU}</span>
            </div>
          ))
        }
      </div>
      <div className="drawer-bottom">
        <button className="btn btn-success" onClick={() => nextStep(3)}>{i18next.t('new_recipient')}</button>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(SelectDestinatario);