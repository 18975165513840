import React from 'react';
import Header from '../components/home/Header';
import Slider from '../components/home/Slider';
import Novedades from '../components/home/Novedades';
import Beneficios from '../components/home/Beneficios';
import DatosUtiles from '../components/shared/DatosUtiles';
import AppMobile from '../components/home/AppMobile';
import Footer from '../components/shared/Footer';

function Home() {
  return (
    <div>
      <Header />
      <Slider />
      <Novedades />
      <Beneficios />
      <AppMobile />
      <DatosUtiles />
      <Footer />
    </div>
  );
}

export default Home;
