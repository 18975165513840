import React, { useState } from 'react';

// Components
import SelectDestinatario from './SelectDestinatario';
import NuevoDestinatario from './NuevoDestinatario';
import MontoTransferir from './MontoTransferir';
import VerificarCuenta from './VerificarCuenta';
import TransferenciaExitosa from './TransferenciaExitosa';
import TransferenciaFallida from './TransferenciaFallida';

import mockDestinatarios from "./mock-destinatarios";
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
function DrawerLayout({ monto, updateMonto, drawerIsShow, hideDrawer, user }) {
  let destinatarios = mockDestinatarios;

  let [step, setStep] = useState(1)
  let [selectedDestinatario, setSelectedDestinatario] = useState(null)
  let [importe, setImporte] = useState(null)

  const _hideDrawer = () => {
    hideDrawer()
    setStep(1)
  }

  const changeStep = (step) => {
    setStep(step)
  }

  const selectDestinatario = (id) => {
    let destinatario = destinatarios.find(destinatario => destinatario.id === id);
    setSelectedDestinatario(destinatario)
  }

  const updateImporte = (importe) => {
    setImporte(importe)
  }

  const loadStep = (step) => {
    switch (step) {
      case 1:
        return (
          <SelectDestinatario
            destinatarios={destinatarios}
            selectDestinatario={selectDestinatario}
            changeStep={changeStep} />
        )
      case 2:
        return (
          <MontoTransferir
            user={user}
            destinatario={selectedDestinatario}
            changeStep={changeStep}
            monto={monto}
            updateMonto={updateMonto}
          />
        )
      case 3:
        return (
          <NuevoDestinatario
            user={user}
            changeStep={changeStep}
            monto={monto}
            updateImporte={updateImporte}
          />
        )
      case 4:
        return (
          <VerificarCuenta
            user={user}
            changeStep={changeStep}
            importe={importe}
            monto={monto}
            updateMonto={updateMonto}
          />
        )
      case 5:
        return (
          <TransferenciaExitosa
            hideDrawer={_hideDrawer}
            changeStep={changeStep} />
        )
      case 6:
        return (
          <TransferenciaFallida
            hideDrawer={_hideDrawer}
            changeStep={changeStep} />
        )
      default:
        return false;
    }
  }

  return (
    <div id="drawer" className={`${drawerIsShow ? "active" : ""}`}>
      <div className={`drawer-card ${drawerIsShow ? "active" : ""}`}>
        <div className="drawer-top">
          <span>{i18next.t('new_transfer')}</span>
          <img src="img/times-solid.svg" onClick={_hideDrawer} alt="" />
        </div>
        {loadStep(step)}
      </div>
    </div>
  );
}

export default withNamespaces()(DrawerLayout);