import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
// import i18n from "i18next";
import i18n from '../../i18n';

function Header({ t }) {
  // let lang = 'es';
  // let [lang] = useState('Español');
  const languages = [
    { code: 'es', icon: 'es', label: 'Español' },
    { code: 'en', icon: 'us', label: 'English' },
  ]
  const getLangLabel = (code) => {
    return languages.find(l => l.code === code);
  };
  const [lang, setLang] = useState(getLangLabel(i18n.languages[0]));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(getLangLabel(lng));
  }

  return (
    <header id="header-home">

      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <img className="logo" src="img/logo.png" alt="" />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link" >{t('PERSONS')}</span>
              </li>
              <li className="nav-item">
                <span className="nav-link" >{t('PREMIUM')}</span>
              </li>
              <li className="nav-item">
                <span className="nav-link" >{t('Companies')}</span>
              </li>
              <li className="nav-item">
                <span className="nav-link" >{t('About_us')}</span>
              </li>
              <li>
                <Link className="d-block d-lg-none" to="/login">
                  <button className="btn btn-outline-primary">{t('online_banking')}</button>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className={`flag-icon flag-icon-${lang.icon}`}></span> {lang.label}
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {languages.map((item, index) => {
                    return <button key={index} className="dropdown-item" onClick={() => changeLanguage(item.code)}><span className={`flag-icon flag-icon-${item.icon}`}> </span> {item.label}</button>
                  })}
                </div>
              </li>
            </ul>
          </div>
          <Link className="d-none d-lg-block" to="/login">
            <button className="btn btn-outline-primary">{t('online_banking')}</button>
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default withNamespaces()(Header);
