import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
function DashboardAccesos(props) {

  const showDrawer = () => {
    props.showDrawer();
  }

  return (
    <section id="dashboard-accesos">
      <div className="container">
        <h2 className="section-title">{i18next.t('Quick_accesses')}</h2>
        <div className="row">
          <div className="col">
            <div className="accesos-list">
              <div onClick={showDrawer}>
                <img src="img/ar1.svg" alt="" />
                <span>{i18next.t('Transfers')}</span>
              </div>
              <div>
                <img src="img/ar2.svg" alt="" />
                <span>{i18next.t('Service_payment')}</span>
              </div>
              <div>
                <img src="img/ar3.svg" alt="" />
                <span>{i18next.t('Fixed_policy')}</span>
              </div>
              <div>
                <img src="img/ar4.svg" alt="" />
                <span>{i18next.t('Receipts')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withNamespaces()(DashboardAccesos);