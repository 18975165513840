import React from 'react';

function BacklogCardItem(props) {
  //const { fecha_hora, user, importe, quality, similarity, riesgo_total, ip, browser, os, country, city, isp } = props.data;
  const { fecha_hora, user, importe,  riesgo_total, ip, browser, os, country, city, isp } = props.data;
  let riesgoClass;
  let iconUrl;

  if (+riesgo_total <= 550) {
    riesgoClass = "success"
    iconUrl = "succes-backlog.svg"
  } else if (+riesgo_total <= 800) {
    riesgoClass = "warning"
    iconUrl = "exclamation-circle-regular-warning.svg"
  } else {
    riesgoClass = "danger"
    iconUrl = "exclamation-circle-regular-danger.svg"
  }

  return (
    <div className="row-card">
      <div className="row-top">
        <span className="table-fecha">{fecha_hora}</span>
        <span className="table-usuario">{user}</span>
        {importe && <span className="table-importe"><b>${importe}</b></span>}
        {/* <span className="table-quality"><b>{quality}%</b></span> */}
        {/* <span className="table-similarity"><b>{similarity}%</b></span> */}
        <span className={`table-riesgo table-riesgo-data ${riesgoClass}`}>
          <span><b>{riesgo_total}</b></span>
          <img src={`../img/${iconUrl}`} alt="" />
        </span>
      </div>
      <div className="row-bottom">
        <span>IP: {ip}</span>
        <span>Browser: {browser}</span>
        <span>OS: {os}</span>
        <span>Country: {country}</span>
        <span>City: {city}</span>
        <span>ISP: {isp}</span>
      </div>
    </div>
  );
}

export default BacklogCardItem;