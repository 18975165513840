import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

function BacklogHeader(props) {
  return (
    <section id="backlog-header">
      <div className="container container-top">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/backend">
            <img className="logo" src="../img/logo-white.png" alt="" />
          </Link>
          <img onClick={props.logOut} className="log-out" src="../img/power-off-solid-white.svg" alt="" />
        </div>
      </div>

      <div className="container">
        <div className="links">
          <NavLink to="/backend/ingresos" activeClassName="current">{i18next.t('income')}</NavLink>
          <NavLink to="/backend/transferencias" activeClassName="current">{i18next.t('Transfers')}</NavLink>
        </div>
      </div>
    </section >
  );
}

export default withNamespaces()(BacklogHeader);
