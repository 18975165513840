import { API_ROUTE } from "./../conexion";

const CHALLENGE = "REDIRECT_CHALLENGE";
const DECLINE = "DECLINE";

async function sendTransaction(data) {
   
  return new Promise(function(resolve, reject) {
    
    // or
    // reject ("Error!");
    fetch(API_ROUTE + "transaccion.php", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("res server 1", response)
        if (!response) {
          console.log("res server 2")
          //("Ocurrió un problema al realizar la transacción, inténtelo de nuevo más tarde");
          resolve({
            status: 3,
            msg: "Ocurrió un problema al realizar la transacción, inténtelo de nuevo más tarde",
            data: null,
          });
          return;
        }
  
  
        if (
          !response.success && response.response.includes(DECLINE)
        ) {
          console.log("res server 3", response.msg)
          
          resolve({ status: 0, msg: response.msg, data: null });
          return
        }
  
        if (        
          !response.success && response.response.includes(CHALLENGE)
        ) {
          console.log("res server 4")
          resolve( { status: 1, msg: response.msg, data: null });
          return
        }
  
        if (response.success) {
          const data = response.data;
          console.log("🚀 ~ transferir ~ data", data);
  
          resolve({ status: 2, msg: null, data: data });
          return
        }
        console.log("res server 5")
      })
      .catch((error) => {
        console.error("Error:", error);
        reject({
          status: 3,
          msg: "Ocurrió un problema al realizar la transacción, inténtelo de nuevo más tarde",
          data: null
        });
        
       })


  });
  

 
}

export default sendTransaction;
