import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FooterMini from '../components/shared/FooterMini';

import { API_ROUTE } from "../conexion";
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';


function Registro(props) {
  const [errors, setErrors] = useState({});
  let [image, setImage] = useState(null);
  let [imageShow, setImageShow] = useState("/img/user-circle-registro.svg");
  let [user, setUser] = useState(null);
  let [password, setPassword] = useState(null);
  let [passwordConfirm, setPasswordConfirm] = useState(null);
  let [dbError, setdbError] = useState(null);

  // Input imagen - Preview de la imagen
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0])
      setImageShow(URL.createObjectURL(e.target.files[0]))
    }
  }

  // Input usuario
  const handleUserChange = (e) => setUser(e.target.value)

  // Input password
  const handlePasswordChange = (e) => setPassword(e.target.value)

  // Input password confirm
  const handlePasswordConfirmChange = (e) => setPasswordConfirm(e.target.value)

  const createUser = (e) => {
    e.preventDefault();

    // Validar campos
    let errors = {}
    if (!user) errors.usuario = "El usuario es requerido"
    if (!password) errors.password = "La contraseña no puede estar vacía"
    if (!passwordConfirm)
      errors.passwordConfirm = "La contraseña no puede estar vacía"
    else if (password !== passwordConfirm)
      errors.passwordConfirm = "Las contraseñas no coinciden"

    setErrors(errors)

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    // Capturo los datos del usuario y checkeo contra base de datos
    let formData = new FormData()
    formData.append("user", user)
    formData.append("password", password)
    formData.append("imagen", image)

    // Realizo la consulta a la base de datos
    fetch(API_ROUTE + "registro.php", {
      method: 'POST',
      body: formData,
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        if (response.success) {
          // redirecciono al usuario al login
          props.history.push({
            pathname: '/login',
            registerStatus: true
          })
        } else {
          setdbError(response.msg)
        }
      })
  }

  return (
    <div>
      <section className="login">
        <div className="background-grey"></div>
        <div className="container">
          <div className="row">
            <div className="col col-header">
              <Link className="back-link" to="/login">{i18next.t('return')}</Link>
              <Link to="/">
                <img className="logo" src="img/logo.png" alt="" />
              </Link>
              <div className="spacer"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-center col-lg-6">
              <h2>{i18next.t('account_creation')}</h2>

              <div className="card">
                <form onSubmit={createUser}>
                  <div className="form-group form-group-image">
                    <label htmlFor="imagen">
                      <img src={imageShow} alt="" />
                      <span>{i18next.t('upload_creation')}</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imagen"
                      name="imagen"
                      onChange={handleImageChange} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="usuario">{i18next.t('User')}</label>
                    <input
                      type="text"
                      className={`form-control ${errors.usuario && "is-invalid"}`}
                      id="usuario"
                      name="usuario"
                      placeholder={i18next.t('Login_user')}
                      onChange={handleUserChange}
                      value={user || ""} />
                    {errors.usuario && (
                      <div className="invalid-feedback">{errors.usuario}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">{i18next.t('Password')}</label>
                    <input
                      type="password"
                      className={`form-control ${errors.password && "is-invalid"}`}
                      id="password"
                      name="password"
                      placeholder={i18next.t('Enter_password')}
                      onChange={handlePasswordChange}
                      value={password || ""} />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password_confirm">{i18next.t('repeat_password')}</label>
                    <input
                      type="password"
                      className={`form-control ${errors.passwordConfirm && "is-invalid"}`}
                      id="password_confirm"
                      name="password_confirm"
                      placeholder={i18next.t('Enter_password')}
                      onChange={handlePasswordConfirmChange}
                      value={passwordConfirm || ""} />
                    {errors.passwordConfirm && (
                      <div className="invalid-feedback">{errors.passwordConfirm}</div>
                    )}
                  </div>
                  {dbError && <div className="text-danger text-center">{dbError}</div>}
                  <button type="submit" className="btn btn-success">{i18next.t('create_account')}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterMini />
    </div>
  );
}

export default withNamespaces()(Registro);

