import React, { useState } from 'react';
import addLog from "../../functions/addLog";
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
import sendTransaction from '../../functions/transaction';
import getDevicePrint from '../../functions/getDevicePrint';

function MontoTransferir({ destinatario, monto, updateMonto, changeStep, user }) {
  const [importe, setImporte] = useState(null);
  const [errors, setErrors] = useState({});

  // Actualizo el valor del importe
  const onChangeImporte = (e) => setImporte(e.target.value)

  const transferir = async (e) => {
    e.preventDefault();

    let data = {
      user,
      amount: importe * 100,
      account: destinatario.CBU,
      sameBank: true,
      meToMe: false,
      haciaOtros: true,
      ctaInscrita: true,
      devicePrint: ''
    }
    data.devicePrint = await getDevicePrint();

    // Validar campos
    let errors = {}
    
    if (!importe)
      errors.importe = i18next.t('empty_amount');
    else if (isNaN(importe))
      errors.importe = i18next.t('number_amount');
    else if (monto < importe) {
      console.log("no_balance")
      errors.importe = i18next.t('no_balance');
      let resImport = await sendTransaction(data);
      console.log(" res 0 ", resImport);
    }

    setErrors(errors)

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    // Realizo resta del monto
    monto = monto - importe

 

    // Cargo los datos en el log
    addLog({
      tipo: "transferencia",
      usuario: user,
      transferStatus: 1,
      importe: importe
    })
    
    let res = await sendTransaction(data);
    
    console.log(" res 1 ", res);

    if(res && res.status === 0){
      //declino
      // Cargo los datos en el log
      console.log(" res 2 ", res);
      addLog({
        tipo: "transferencia",
        usuario: user,
        transferStatus: 3,
        importe: importe,
      });
      changeStep(6); 
      return;
    }

    if(res && res.status === 1){
       //Verifar cuenta
       console.log(" res 3 ", res);
       changeStep(4); 
      return;
    }

    // Informo el nuevo monto
    updateMonto(monto)

    console.log(" res 4 ", res);
    // transferencia exitosa
    changeStep(5);
  }

  return (
    <React.Fragment>
      <div id="monto-transferir" className="drawer-content">
        <h2 className="drawer-title">{destinatario.name}</h2>
        {/* <p className="drawer-legend">Importe</p> */}

        <form onSubmit={transferir}>
          <div className="form-group form-group-custom">
            <label htmlFor="importe">{i18next.t('amount').toLowerCase()}</label>
            <input
              type="text"
              className={`form-control form-control-custom form-control-importe ${errors.importe && "is-invalid"}`}
              id="importe"
              name="importe"
              placeholder="$0.00"
              value={importe || ""}
              onChange={onChangeImporte} />
            <div className="invalid-feedback">{errors.importe}</div>
          </div>

          <ul>
            <li>
              <span>{i18next.t('Origin')}</span>
              <b>${new Intl.NumberFormat().format(monto)}</b>
            </li>
            <li>
              <span>{i18next.t('Destiny')}</span>
              <b>{destinatario.CBU}</b>
            </li>
            <li>
              <span>{i18next.t('Bank')}</span>
              <b>Banco Frances</b>
            </li>
            <li>
              <span>{i18next.t('Addressee')}</span>
              <b>{destinatario.name}</b>
            </li>
            <li>
              <span>{i18next.t('Accreditation')}</span>
              <b>{i18next.t('Immediate')}</b>
            </li>
          </ul>
          <input type="submit" className="sr-only" />
        </form>
        <button className="btn btn-success" onClick={transferir}>{i18next.t('transfer')}</button>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(MontoTransferir);