import React from "react";
import { withNamespaces, Trans } from "react-i18next";

function Message() {
  return (
    <div
      className="alert alert-success alert-dismissible alert-login"
      role="alert"
    >
      <img className="mr-2" src="img/check-solid.svg" alt="" />
      <div>
        <div className="alert-title">
          <Trans i18nKey="count_created">Cuenta Creada</Trans>
        </div>
        <br></br>

        <div className="alert-message">
          <Trans i18nKey="count_created_msg">
            La cuenta ha sido creada correctamente
          </Trans>
        </div>
      </div>

      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

//export default Message;
export default withNamespaces()(Message);
