import React from 'react';
import { Link } from 'react-router-dom';

function DashboardHeader({ user }) {
  const logOut = () => {
    console.log('hola mundo')
    localStorage.setItem('userLogin', true);
  };
  return (
    <section id="dashboard-header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={{ pathname: "/login", validUser: true }}>
            <img className="logo" src="/img/logo.png" alt="" />
          </Link>

          <div className="cuenta">
            {
              (user.img_src !== "") ?
                <img className="user-avatar mr-2" src={`avatar/${user.img_src}`} alt="" /> :
                <img className="user-avatar mr-2" src="img/user-circle-regular.svg" alt="" />
            }
            <span>{user.user}</span>
            <Link to={{ pathname: "/login", validUser: false }} onClick={logOut}>
              <img className="log-out" src="img/power-off-solid.svg" alt="" />

            </Link>
          </div>

        </div>
      </div>
    </section >
  );
}

export default DashboardHeader;