import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
function DatosUtiles({ t }) {
  return (
    <section id="datos-utiles">
      <div className="container">
        <h2 className="section-title">{t('Useful_information')}</h2>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-12">
            <div className="media">
              <img src="img/dt-1.svg" className="mr-3" alt="..." />
              <div className="media-body">
                <h3 className="card-title mt-0">{t('Need_Help')}</h3>
                <span><Trans i18nKey="Call_us_at">Llámanos al <span className="tel">902 323 000</span> Estamos disponible todos los días, las 24 horas.</Trans></span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-12">
            <div className="media">
              <img src="img/dt-2.svg" className="mr-3" alt="..." />
              <div className="media-body">
                <h3 className="card-title mt-0">{t('Branch_offices')}</h3>
                <span><Trans i18nKey="Branch_locator">Usa nuestro <span>localizador de sucursales</span></Trans></span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-12">
            <div className="media">
              <img src="img/dt-3.svg" className="mr-3" alt="..." />
              <div className="media-body">
                <h3 className="card-title mt-0">Twitter</h3>
                <span><Trans i18nKey="Twitter_at_kymabank">Síguenos en Twitter en <span>@kymabank</span></Trans></span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-12">
            <div className="media">
              <img src="img/dt-4.svg" className="mr-3" alt="..." />
              <div className="media-body">
                <h3 className="card-title mt-0">{t('have_questions')}</h3>
                <span><Trans i18nKey="Email_Help">Envíanos un email <span>ayuda@kymabank.com</span></Trans></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withNamespaces()(DatosUtiles);