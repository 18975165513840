import React, { useState } from 'react';
import addLog from "../../functions/addLog";
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
import sendTransactionNotify from '../../functions/notify';
import getDevicePrint from '../../functions/getDevicePrint';


function VerificarCuenta({ monto, importe, changeStep, updateMonto, user }) {
  const [token, setToken] = useState(null);
  const [errors, setErrors] = useState({});

  // Actualizo el valor del token
  const onChangeToken = (e) => setToken(e.target.value)

  const transferir = async (e) => {
    e.preventDefault();
    console.log("transferir", monto);
    let data = {
      user,
      devicePrint: await getDevicePrint()
    }

    // Validar campos
    let errors = {}
    if (!token)
      errors.token = i18next.t('empty_token');
    else if (isNaN(token))
      errors.token = i18next.t('numeric_token');

    setErrors(errors)
    console.log("transferir 2", monto);
    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    addLog({
      tipo: "transferencia",
      usuario: user,
      transferStatus: 2,
      importe: importe
    })
    console.log("transferir", monto, " importe ", importe);
    updateMonto(monto - importe);

    //sendTransactionNotify(data);
    
    changeStep(5);
  }

  return (
    <React.Fragment>
      <div id="verificar-cuenta" className="drawer-content">
        <div>
          <h2 className="drawer-title">{i18next.t('Latest_news')}</h2>
          <p className="drawer-legend mb-0">{i18next.t('have_enter')}</p>
        </div>

        <form onSubmit={transferir}>
          <img src="img/password.svg" alt="" />
          <div className="form-group form-group-custom">
            <label htmlFor="token">{i18next.t('token')}</label>
            <input
              type="text"
              className={`form-control form-control-custom ${errors.token && "is-invalid"}`}
              id="token"
              name="token"
              value={token || ""}
              placeholder="Ingresar Token"
              onChange={onChangeToken} />
            {
              errors.token ?
                <div className="form-text invalid-feedback">{errors.token}</div> :
                <small className="form-text text-muted">{i18next.t('enter_token')}</small>
            }
          </div>
          <input type="submit" className="sr-only" />
        </form>
        <button className="btn btn-success" onClick={transferir}>{i18next.t('transfer')}</button>

      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(VerificarCuenta);