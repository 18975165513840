import React from 'react';
import { withNamespaces } from 'react-i18next';

function DashboardSubHeader({ t }) {
  return (
    <section id="dashboard-sub-header">
      <div className="container">
        <img className="img-fluid" src={t('banner_bank')} alt="" />
      </div>
    </section>
  );
}

export default withNamespaces()(DashboardSubHeader);