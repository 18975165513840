import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
function FooterMini() {
  return (
    <footer>
      <div className="footer-bottom">
        <span>
          <Trans i18nKey="RIGHTS_RESERVED"
            defaults=" Kyma Bank {{ date }}. TODOS LOS DERECHOS RESERVADOS."
            values={{ date: new Date().getFullYear() }}>
          </Trans>
        </span>
      </div>
    </footer>
  );
}

export default withNamespaces()(FooterMini);