const mockDestinatarios = [
  {
    id: 1,
    name: "Rhoda Leonard",
    CBU: "9091716510186245187625"
  },
  {
    id: 2,
    name: "Mable Gutierrez",
    CBU: "1083628719287352765232"
  },
  {
    id: 3,
    name: "Hilda Hall",
    CBU: "0101918161515239383838"
  },
  {
    id: 4,
    name: "Beatrice Moran",
    CBU: "9543789566430009456732"
  },
  {
    id: 5,
    name: "Winifred Goodman",
    CBU: "0000998845326593859456"
  },
  {
    id: 6,
    name: "Lillie Barton",
    CBU: "90117165131800058373942"
  },
];

export default mockDestinatarios;
