import React from 'react';
import { withNamespaces } from 'react-i18next';

function Beneficios({ t }) {
  return (
    <section id="beneficios">
      <div className="container">
        <h2 className="section-title">{t('Benefits')}</h2>
        <div className="row">
          <div className="col">
            <div className="line-wrapper">
              <div className="line">
                <span className="selected">{t('Gastronomy')}</span>
                <span>{t('Shows')}</span>
                <span>{t('Travels')}</span>
                <span>{t('home')}</span>
                <span>{t('Surprises')}</span>
              </div>
              <div className="bottom-line"></div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-img col-lg-4 col-sm-6 col-12">
            <img className="img-fluid" src={t('banner_beneficio_1')} alt="" />
          </div>
          <div className="col-img col-lg-4 col-sm-6 col-12">
            <img className="img-fluid" src={t('banner_beneficio_2')} alt="" />
          </div>
          <div className="col-img col-lg-4 col-sm-6 col-12">
            <img className="img-fluid" src={t('banner_beneficio_3')} alt="" />
          </div>
          <div className="col-img col-lg-12 col-sm-6 col-12">
            <img className="img-fluid d-block d-lg-none" src={t('banner_beneficio_4')} alt="" />
            <img className="img-fluid d-none d-lg-block" src={t('banner_beneficio_big')} alt="" />
          </div>
        </div>
      </div>

    </section>
  );
}

export default withNamespaces()(Beneficios);