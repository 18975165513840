import React from 'react';
import { withNamespaces } from 'react-i18next';
function AppMobile({ t }) {
  return (
    <section id="app-mobile">
      <div className="bk-app-mobile bk-app-mobile-desktop" style={{ backgroundImage: "url(img/app-full.jpg)" }}></div>
      <div className="bk-app-mobile bk-app-mobile-mobile" style={{ backgroundImage: "url(img/celu-mobile.jpg)" }}></div>

      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card">
              <img src="img/pareja-destacado.jpg" alt="..." />
              <div className="content">
                <h3 className="section-title">{t('operate_kyma_bank')}</h3>
                <span>{t('cell_phone_access')}</span>
                <button type="button" className="btn btn-outline-light">{t('Order_it_now')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withNamespaces()(AppMobile);