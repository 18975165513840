import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Registro from './pages/Registro';
import Dashboard from './pages/Dashboard';

import BacklogLogin from './pages/BacklogLogin';
import BacklogIngresos from './pages/BacklogIngresos';
import BacklogTransferencias from './pages/BacklogTransferencias';
import LoginFailed from './pages/LoginFailed';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// the hoc
import { withNamespaces } from 'react-i18next';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App({ t }) {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/login-fail" component={LoginFailed} />
        <Route path="/registro" component={Registro} />
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/backend" component={BacklogLogin} />
        <Route path="/backend/ingresos" component={BacklogIngresos} />
        <Route path="/backend/transferencias" component={BacklogTransferencias} />
      </Router>
    </div>
  );
}

export default withNamespaces()(App);
