async function getDevicePrint() {
    let devicePrint = '';
    if (typeof window.encode_deviceprint === 'function') {
        try {
            devicePrint = await window.encode_deviceprint();
        } catch (error) {
            console.error("🚀 ~ error ~ devicePrint", error)
        }
    }
    console.log("🚀 ~ file: getDevicePrint.js ~ line 3 ~ getDevicePrint ~ devicePrint", devicePrint)
    return devicePrint;
}
export default getDevicePrint;