import React from "react";
import { Link } from "react-router-dom";
import FooterMini from "../components/shared/FooterMini";
import { Trans } from "react-i18next";

function LoginFailed() {
  return (
    <div id="login-layout">
      <section className="login">
        <div className="background-grey"></div>
        <div className="container">
          <div className="row">
            <div className="col col-header">
              <h2 className="mb-5">
                <Link
                  className="back-link"
                  to={{ pathname: "/login", validUser: false }}
                >
                  Volver
                </Link>
              </h2>

              <Link to={{ pathname: "/login", validUser: true }}>
                <img className="logo" src="img/logo.png" alt="" />
              </Link>
              <div className="spacer"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-center col-lg-8">
              <h2 className="mb-5">
                <div className="text-danger d-block text-center mb-2">
                  <Trans i18nKey="login_atention">¡Atención!</Trans>
                </div>

                <div className="text-dark">
                  <Trans i18nKey="login_not_secure">
                    No estamos seguros de que sea usted
                  </Trans>
                </div>
              </h2>

              <div
                className="alert alert-primary d-block w-100 mb-5"
                role="alert"
              >
                <Trans i18nKey="is_error">
                  Si se trata de un error comuniquese con nosotros al{" "}
                  <b>0800 555 5555</b>
                </Trans>
              </div>

              <img
                className="img-fluid img-policia"
                src="img/atencion-policia.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <FooterMini />
    </div>
  );
}

export default LoginFailed;
