import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';

function Novedades({ t }) {
  return (

    <section id="novedades">
      <div className="container">
        {/* <h1>{t('welcome')}</h1> */}
        <h2 className="section-title">{t('Latest_news')}</h2>
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="card">
              <p className="card-text"><Trans i18nKey="home_insurance">Ahora puedes contratar tu <span>seguro de vivienda</span> desde la APP de Kyma.</Trans></p>
              <span className="link">{t('Learn_more')} →</span>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="card">
              <p className="card-text"><Trans i18nKey="operate_bank">Conoce una <span>manera diferente</span> de operar tu banco.</Trans></p>
              <span className="link">{t('Learn_more')} →</span>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="card">
              <p className="card-text"><Trans i18nKey="Redeem_kyma_points">Canjea tus <span>Puntos Kyma</span> desde la APP de forma fácil y rápida.</Trans></p>
              <span className="link">{t('Learn_more')} →</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withNamespaces()(Novedades);